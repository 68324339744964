<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "",
  props: [""],
  data() {
    return {};
  },
  components: {},
  created() {},
};
</script>
<style scoped>
</style>